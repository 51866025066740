import React from "react"
import { Assets } from "../../assets"

export const Team = () => {
  return (
    <div className="equipoHumano">
      <h2>EQUIPO HUMANO</h2>
      <p>
        Semte Instalaciones está formada por un equipo joven y altamente
        cualificado. El buen ambiente de trabajo es síntoma de eficacia y
        compromiso.
      </p>
      <div className="teamImages">
        <div>
          <img alt="Ana Macías" src={Assets.ana} />
          <p>
            Ana Macías
            <br />
            Administración
          </p>
        </div>
        <div>
          <img alt="Frank Tedesko" src={Assets.franky} />
          <p>
            Frank Tedesko
            <br />
            Dirección Técnica
          </p>
        </div>
        <div>
          <img alt="Jose Miguel Matas" src={Assets.semi} />
          <p>
            Jose Miguel Matas
            <br />
            Producción
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <picture className="team-picture">
          <source
            media="(min-width:1920px)"
            srcSet={Assets.team.bigWebp}
            type="image/webp"
          />
          <source
            media="(min-width:1920px)"
            srcSet={Assets.team.big}
            type="image/jpg"
          />
          <source srcSet={Assets.team.smallWebp} type="image/webp" />
          <img src={Assets.team.small} alt="team" style={{ width: "100%" }} />
        </picture>
      </div>
    </div>
  )
}
