import React from "react"
import { Layout } from "../components/Layout"
import { Team } from "../components/Team/Team"

const equipo = props => {
  return (
    <Layout Jumbo={true}>
      <Team />
    </Layout>
  )
}

export default equipo
